import * as React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Blocks from "../components/Home/Blocks"
import FormationsHome from "../components/Home/Formations"
import Carousel from "../components/Carousel"
import SwiperHome from "../components/SwiperHome"
import JumbotronCarousel from "../components/Home/JumbotronCarousel"
import { SEO } from "../components/seo"

const IndexPage = ({ location, data }) => {
  const HOME = data.strapiAccueil
  return (
    <Layout path={location.pathname}>
      {data.strapiAccueil.carousel.carouselItem?.length > 0 && (
        <SwiperHome panels={HOME.carousel.carouselItem} />
      )}
      <FormationsHome />
      <Blocks blocks={data.strapiAccueil.blocks} />
    </Layout>
  )
}

export default IndexPage

export const Head = ({ data }) => (
  <SEO
    title={data.strapiAccueil.titre}
    description={data.strapiAccueil.description}
    canonical={`https://www.lhsl.fr`}
  />
)

export const query = graphql`
  query IndexFrQuery {
    strapiAccueil {
      titre
      locale
      description
      carousel {
        carouselItem {
          id
          strapi_id
          titre
          titre2
          description
          labelLien
          destinationLien
          illustration {
            name
            mime
            caption
            alternativeText
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(quality: 80, backgroundColor: "#333d51")
              }
            }
          }
        }
      }
      blocks {
        __typename
        ...STRAPI__COMPONENT_ACCUEIL_SPOTLIGHT
        ...STRAPI__COMPONENT_ACCUEIL_BANNIERE
        ...STRAPI__COMPONENT_ACCUEIL_MOT_DU_PROVISEUR
        ...STRAPI__COMPONENT_ACCUEIL_VIDEO_SPOTLIGHT
      }
    }
  }
`
